body
{
	&.work
	{
		/* this sits behind .top-header ; on this page, it is fixed just like .top-header and fades in when page scrolls */
		.top-header-height {
			position: fixed;
			opacity: 0;
			background-color: #fff;
			z-index: 10;
			transition: opacity 0.2s linear;
			&.opaque {
				opacity: 1;
			}
			@include breakpoint(medium down) {
				opacity: 1;
			}
		}

		.kontrast__body
		{
			.kontrast__content
			{
				@include breakpoint(large)
    			{
					padding: 0 0 rem-calc(70);
				}

				.bg
				{
					@include breakpoint(large)
    				{
						height: rem-calc(730);
						padding-top: rem-calc(120);
						background-image: url('/images/photos/work-background.jpg');
						background-repeat: no-repeat;
						background-position: 50% 50%;
						background-size: cover;
						margin-bottom: rem-calc(30);
	    			}
					@include breakpoint(medium down) {
						padding-top: $top-header-height;
						background: none;
					}

					.text
					{
						display: flex;
						display: -webkit-flex;
						flex-direction: column;

						@include breakpoint(large)
    					{
							height: calc(730px - 120px);
						}

						div
						{
							&:first-child
							{
								-webkit-box-flex: 1;
								flex-grow: 1;
							}
						}
					}

					h2
					{
						@include breakpoint(large)
    					{
							// color: $white;
							color: $black;
						}
					}

					h3
					{
						margin: 0 0 rem-calc(30);
						font-size: rem-calc(15);
						font-family: 'AvenirLTStd-Roman';

						@include breakpoint(large)
    					{
							// color: $white;
							color: $black;
    					}
					}
				}

				.images
				{
					@include breakpoint(large)
    				{
						margin-top: rem-calc(50);
						margin-bottom: 0;
					}
				}

				.work__item
				{
					margin-bottom: rem-calc(80);

					@include breakpoint(large)
    				{
    					margin-bottom: 0;
    				}

					img {
						width: 100%;
					}
					position: relative;
					.gallery-name {
						position: absolute;
						left: 30px;
						bottom: 15px;
						font-size: rem-calc(15);
						font-family: 'AvenirLTStd-Roman';
						color: #fff;
					}

					.work__bg
					{
						/* seems to be defunct */
						display: flex;
						display: -webkit-flex;
						width: 100%;
						height: rem-calc(220);
						padding: rem-calc(20);
						background-size: cover;
						background-position: 50% 50%;
						background-repeat: no-repeat;

						@include breakpoint(large)
    					{
							height: rem-calc(350);
    					}

						h4
						{
							/*BugHerd:437*/color:white;
							display: flex;
							display: -webkit-flex;
							align-items: flex-end;
							width: 100%;
							margin: 0;
							font-size: rem-calc(15);
							font-family: 'AvenirLTStd-Roman';
							position: relative;
							bottom: rem-calc(-60);
							left: rem-calc(-20);
							
							color: $black;
							
							@include breakpoint(large)
    						{
								color: $white;
								bottom: 0;
								left: 0;
    						}
						}
					}

					a
					{
						color: $black;

						@include breakpoint(large)
    					{
							color: $white;
    					}
					}
				}
			}
		}

		.is-open-right
		{
			.hamburger
			{
				.hamburger__box
				{
					.hamburger__inner
					{
						background: $white;

						&:before
						{
							background: $white;
						}

						&:after
						{
							background: $white;
						}
					}
				}
			}

			header
			{
				.logo
				{
					background-image: url('/img/admin/kontrast-alt.png');
				}
			}
		}
	}
}



