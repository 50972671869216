footer
{
	padding: rem-calc(40) rem-calc(20);
	background: $pale-green;
	text-align: center;

	@include breakpoint(large)
	{
		margin-top: rem-calc(60);
		padding: rem-calc(40) 0;
		text-align: left;
	}

	p
	{
		margin: 0;
		color: $white;
		font-size: rem-calc(15);
		letter-spacing: rem-calc(0.5);
		font-family: 'AvenirLTStd-Roman';

		br
		{
			display: block;

			@include breakpoint(large)
			{
				display: none;
			}	
		}

		a
		{
			color: $white;

			&:hover
			{
				color: $white;
				text-decoration: none;
			}
		}
	}

	.icon
	{
		display: inline-block;
		width: rem-calc(40);
		height: rem-calc(40);
		margin: rem-calc(10) auto;
		border-radius: 50%;
		background: $white;
		font-size: rem-calc(26);
		line-height: rem-calc(46);
		text-align: center;

		@include breakpoint(large)
		{
			width: rem-calc(50);
			height: rem-calc(50);
			margin: 0 rem-calc(15) rem-calc(15) 0;
			font-size: rem-calc(30);
			line-height: rem-calc(56);
		}

		span
		{
			color: $pale-green;
		}
	}
	
	.mc {
		@include breakpoint(small only)
		{
			border-bottom: 0 none !important;
		}
		
		@include breakpoint(medium only)
		{
			border-bottom: 0 none !important;
		}
	}
}