.images
{
	margin-top: rem-calc(30);
	margin-bottom: rem-calc(30);

	@include breakpoint(large)
	{
		margin-top: rem-calc(80);
		margin-bottom: rem-calc(100);
	}

	.image__bg
	{
		width: 100%;
		height: rem-calc(220);
		background-repeat: no-repeat;
		background-position: 50% 50%;
		background-size: cover;

		@include breakpoint(large)
		{
			height: rem-calc(350);
		}
	}
}

 