body {
	// margin-top: rem-calc(120);
}

$top-header-height: rem-calc(120);

header.top-header
{
	// border: red 1px solid;
	
	height: $top-header-height;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 14;

	.logo
	{
		width: rem-calc(140);
		height: rem-calc(36);
		margin-top: rem-calc(30);
		margin-left: rem-calc(20);
		background-image: url('/img/admin/kontrast.png');
		background-size: contain;
		background-position: 0 0;
		background-repeat: no-repeat;
		text-indent: -9999px;

		@include breakpoint(large)
	    {
	    	width: rem-calc(190);
	    	margin-left: 0;
	    }

		a
		{
			display: block;
		}
	}
}
.top-header-height {
	height: $top-header-height;
	width: 100%;
}