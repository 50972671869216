body
{
	&.about
	{
		.top-header {
			background-color: rgba(255,255,255,1);
			transition: background-color 0.1s linear;
		}

		h2
		{
			margin: 0 0 rem-calc(30);

			br
			{
				display: none;
			}

			@include breakpoint(large)
    		{
				margin: 0;

				br
				{
					display: block;
				}
    		}
		}

		.kontrast__content
		{
			padding-bottom: rem-calc(40);

			@include breakpoint(large)
    		{
				padding-bottom: rem-calc(50);
    		}
		}

		.is-open-right
		{
			.top-header {
				background-color: rgba(255,255,255,0);
			}
			.hamburger
			{
				.hamburger__box
				{
					.hamburger__inner
					{
						background: $white;

						&:before
						{
							background: $white;
						}

						&:after
						{
							background: $white;
						}
					}
				}
			}

			header
			{
				.logo
				{
					background-image: url('/img/admin/kontrast-alt.png');
				}
			}
		}
	}
}