/* Template
	<div class="select__wrapper">
		<select class="select" id="">
			<option></option>
		</select>
		<div class="select__arrow__wrapper">
			<div class="select__arrow"></div>	
		</div>
	</div>	
*/

.select__wrapper
{
	background-color: $white;
	position: relative;
	z-index: 10;
	//border: 2px solid $light-grey;
	display: block;
	// set minimum width if necessary
	//min-width: rem-calc(300);
	
	.select 
	{
		padding: rem-calc(7) 0px 0px rem-calc(15);
		margin: 0px;
		background-color: transparent;
		width: 100%;
		height: rem-calc(45);
		z-index: 10;
	 	border: none;
	 	color: #ccc;
	 	-webkit-appearance: none;

	 	option
	 	{
	 		width: 100%;
	 		font-size: rem-calc(15);	
	 		color: #ccc;
	 	}

	 	&:focus
	 	{
	 		border-radius: 0%;
	 		box-shadow: none;
	 	}
	}

	.triangle
	{
		transform: rotate(90deg);
		position: absolute;
		top: rem-calc(12);
		right: rem-calc(10);
		
		@include breakpoint(large)
		{
			top: rem-calc(25);
		}
	}
}