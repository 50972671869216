form
{
	.form__row
	{
		margin: 0 0 rem-calc(22);
	}

	.form-error
	{
		&.is-visible
		{
			margin: 0px;
			margin-top: rem-calc(5);
		}
	}

	input[type="text"],
	input[type="email"]
	{
		height: rem-calc(45);
		margin: 0;
		padding: 0 rem-calc(20);
		border: 0;
		box-shadow: none;
		background: $white;
		color: $black;
		font-size: rem-calc(18);

		@include placeholder
		{
			color: darken($white, 20%);
		}

		&:focus
		{
			outline: none;
			border: 0;
		}
	}

	textarea
	{
		border: 0;
		box-shadow: none;
		background: $white;
		margin: 0;
		padding: rem-calc(20);
		height: rem-calc(200) !important;
		color: $black;
		font-size: rem-calc(18);

		@include placeholder
		{
			color: darken($white, 20%);
		}

		&:focus
		{
			outline: none;
			border: 0;
		}
	}

	button
	{
		height: rem-calc(50);
		padding: 0 rem-calc(20);
		border: 0;
		background: $white;
		color: $pale-green;
		font-size: rem-calc(15);
		font-family: 'Avenir-Heavy';
		text-transform: uppercase;
		cursor: pointer;
	}

	.choose__files__wrapper
	{
		margin-bottom: rem-calc(40);

		input[type="file"]
		{
			width: 0.1px; 
			height: 0.1px;
			opacity: 0;
			overflow: hidden;
			position: absolute;
			z-index: -1;
		}

		label 
		{
			display: inline-block;
			width: auto;
			height: rem-calc(40);
			margin-top: rem-calc(10);
			border: 0;
			padding: 0px rem-calc(5);
			padding-top: rem-calc(5);
			background: $white;
			text-align: center;
			font-size: rem-calc(18);
			font-family: 'Avenir-Heavy';
			color: #ccc;
			cursor: pointer;

			@include breakpoint(large)
			{
				width: rem-calc(125);
				margin: 0;
			}
		}

		.choose__files__status
		{
			display: inline-block;
			margin-left: rem-calc(10);
			font-family: 'AvenirLTStd-Book';
			font-size: rem-calc(20);
			color: $white;
		}

		.help__tip
		{
			margin-top: rem-calc(10);
			font-family: 'AvenirLTStd-Book';
			font-size: rem-calc(20);
			color: $white;	
		}
	}
}