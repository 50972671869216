nav
{
	display: none;

	@include breakpoint(large)
	{
		display: block;
	}

	ul
	{
		li
		{
			a
			{
				padding: rem-calc(3) 0;
				color: $black;
				font-family: 'Avenir-Heavy';
				font-size: rem-calc(14.5);
				letter-spacing: rem-calc(1);

				&:hover
				{
					color: $black;
				}

				&:focus
				{
					outline: none !important;
				}

				&.active
				{
					color: lighten($black, 60%);

					&:hover
					{
						color: lighten($black, 60%);
					}
				}
			}

			&:focus
			{
				outline: none !important;
			}
		}

		&.menu
		{
			margin-top: rem-calc(30);
			margin-left: rem-calc(80);

			a
			{
				padding: rem-calc(3) 0;

				&:focus
				{
					outline: none !important;
				}
			}
		}
	}

	&#mobile__nav
	{
		display: block;
		margin-top: rem-calc(200);
		text-align: center;

		ul
		{
			list-style: none;
			margin: 0 0 rem-calc(20);
			padding: 0;

			li
			{
				a
				{
					color: $white;
					font-size: rem-calc(18);
				}
			}
		}
	}
}

body.contact {
	nav {
		ul {
			li {
				a.active {
					color: #fff !important;
				}
			}
		}
	}
}


.hamburger
{
	float: right;
	margin-top: rem-calc(30);
	margin-right: rem-calc(20);

	.hamburger__box
	{
		display: inline-block;
		width: rem-calc(25);
		height: rem-calc(17);
		position: relative;

		.hamburger__inner
		{
			width: rem-calc(25);
			height: rem-calc(3);
			background: $blue;
			position: absolute;
			top: rem-calc(1.5);

			&:before
			{
				display: block;
				width: rem-calc(25);
				height: rem-calc(3);
				background: $blue;
				content: '';
				position: absolute;
				top: rem-calc(7);
			}

			&:after
			{
				display: block;
				width: rem-calc(25);
				height: rem-calc(3);
				background: $blue;
				content: '';
			    position: absolute;
				top: rem-calc(14);
			}
		}
	}
}


.off-canvas
{
	background: $pale-green;
	box-shadow: none !important;

	@include breakpoint(small only)
	{
		width: 100% !important;
		-webkit-transform: translateX(100%);
    	transform: translateX(100%);
	}

	@include breakpoint(medium only)
	{
		width: 100% !important;
		-webkit-transform: translateX(100%);
    	transform: translateX(100%);
	}
}