body
{
	&.services
	{
		.top-header {
			background-color: rgba(255,255,255,1);
			transition: background-color 0.1s linear;
		}

		.kontrast__content
		{
			padding-bottom: rem-calc(40);

			@include breakpoint(large)
    		{
				padding-bottom: rem-calc(50);
    		}
		}

		.services__bg
		{
			width: 100%;
			height: rem-calc(220);
			background-repeat: no-repeat;
			background-position: 50% 50%;
			background-size: cover;

			@include breakpoint(large)
    		{
				height: rem-calc(350);
    		}
		}

		.services__content
		{
			margin: rem-calc(60) 0 0;

			h3
			{
				color: $black;
				font-weight: 700;
				font-size: rem-calc(22);
				font-family: "adobe-caslon-pro",serif;
			}

			p
			{
				margin: 0;
			}
		}

		.button__inline
		{
			margin: rem-calc(30) 0;

			@include breakpoint(large)
    		{
				margin: rem-calc(30) 0 rem-calc(60);
    		}
		}

		.space
		{
			@include breakpoint(small only)
    		{
				margin-top: 0;
			}

			margin-top: rem-calc(40);

			@include breakpoint(large)
    		{
				margin-top: rem-calc(80);
			}
		}

		.is-open-right
		{
			.top-header {
				background-color: rgba(255,255,255,0);
			}
			.hamburger
			{
				.hamburger__box
				{
					.hamburger__inner
					{
						background: $white;

						&:before
						{
							background: $white;
						}

						&:after
						{
							background: $white;
						}
					}
				}
			}

			header
			{
				.logo
				{
					background-image: url('/img/admin/kontrast-alt.png');
				}
			}
		}
	}
}