body
{
	&.contact
	{
		background: $pale-green;
		.top-header {
			background-color: $pale-green;
		}

		header
		{
			.logo
			{
				background-image: url('/img/admin/kontrast.png');
			}
		}

		.hamburger
		{
			.hamburger__box
			{
				.hamburger__inner
				{
					background: $white;

					&:before
					{
						background: $white;
					}

					&:after
					{
						background: $white;
					}
				}
			}
		}

		.kontrast__body
		{
			h2
			{
				margin: 0 0 rem-calc(30);
				color: $white;
				font-family: 'Avenir-Heavy';
				font-size: rem-calc(18);

				@include breakpoint(large)
	    		{
					margin: 0 0 rem-calc(100);
					font-size: rem-calc(30);
	    		}
			}

			.button__dark
			{
				color: $pale-green;
				text-decoration: none;
			}

			p
			{
				// color: $white;
				color: lighten($black, 20%);
				font-size: rem-calc(15);

				a
				{
					color: $white;
					text-decoration: underline;
				}

				&:last-child
				{
					font-size: rem-calc(16);
				}

				&.dots
				{
					margin: rem-calc(30) 0 rem-calc(35);
					font-size: rem-calc(36);
					line-height: rem-calc(14);
				}

				@include breakpoint(large)
	    		{
					color: lighten($black, 20%);
					font-size: rem-calc(18);
	    		}
			}

			form
			{
				margin-top: rem-calc(50);

				@include breakpoint(large)
	    		{
					margin: 0;
	    		}
			}
			
			.firstColumn {
				@include breakpoint(large)
				{
					padding-right: 60px;
				}
			}
		}
	}
}