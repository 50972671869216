// Foundation
@import "../../../node_modules/foundation-sites/scss/foundation";

// Font Awesome
@import "../../../node_modules/font-awesome/scss/font-awesome";

@import "../../../node_modules/slick-carousel/slick/slick.scss";

// Foundation Imports
@include foundation-global-styles;
// @include foundation-grid;
@include foundation-flex-grid;
@include foundation-flex-classes;
@include foundation-typography;
@include foundation-forms;
@include foundation-button;
@include foundation-accordion;
@include foundation-accordion-menu;
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-button-group;
@include foundation-callout;
@include foundation-card;
@include foundation-close-button;
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-drilldown-menu;
@include foundation-dropdown;
@include foundation-dropdown-menu;
@include foundation-responsive-embed;
@include foundation-label;
@include foundation-media-object;
@include foundation-off-canvas;
@include foundation-orbit;
@include foundation-pagination;
@include foundation-progress-bar;
@include foundation-slider;
@include foundation-sticky;
@include foundation-reveal;
@include foundation-switch;
@include foundation-table;
@include foundation-tabs;
@include foundation-thumbnail;
@include foundation-title-bar;
@include foundation-tooltip;
@include foundation-top-bar;
@include foundation-visibility-classes;
@include foundation-float-classes;

// Globals
@import 'global/colors';
@import 'global/mixins';
@import 'global/typography';

// Base
@import 'base';

// Modules
@import 'modules/header';
@import 'modules/footer';
@import "modules/slick-theme-kontrast";

// Components
@import 'components/forms';
@import 'components/formsSite';
@import 'components/nav';
@import 'components/images';
@import 'components/subscribe';
@import 'components/buttons';
@import 'components/select-box';
@import 'components/triangle';
@import 'components/instagram';
@import 'components/datepicker';
@import 'components/terms';

// Site Templates
@import 'templates/home';
@import 'templates/about';
@import 'templates/contact';
@import 'templates/work';
@import 'templates/services';
@import 'templates/enquire';