.instagram
{
	margin-bottom: rem-calc(30);
	margin-top: rem-calc(30);
	
	@include breakpoint(large)
	{
		margin-bottom: rem-calc(0);
	}

	width: 100%;
	overflow: hidden;

	.instagram__items 
	{
		//@include flex-grid-row();

		@include breakpoint(large)
		{
			margin-left: rem-calc(-40);
			margin-right: rem-calc(-40);
		}

		.item
		{
			//@include flex-grid-column();
			margin-bottom: rem-calc(30);

			@include breakpoint(medium)
			{
				margin-bottom: 0;
			}

			@include breakpoint(large)
			{
				padding-left: rem-calc(40);
				padding-right: rem-calc(40);
			}

			
			a {
				.item__block {
					width:               100%;
					padding-bottom:      100%;
					background-repeat:   no-repeat;
					background-position: 50% 50%;
					background-size:     cover;
					position: relative;
					
					.item__caption {
						opacity: 0;
						
						background: rgba(0, 0, 0, 0.5);
						bottom: 0;
						color: #fff !important;
						display: block;
						left: 0;
						padding-top: 42%;
						position:   absolute;
						right: 0;
						text-align: center;
						top:        0;
						z-index:    2;
						
						-webkit-transition: opacity 0.3s ease-in-out;
						-moz-transition: opacity 0.3s ease-in-out;
						-ms-transition: opacity 0.3s ease-in-out;
						-o-transition: opacity 0.3s ease-in-out;
						
					}
					
				}
				
				&:hover {
					.item__block {
						.item__caption {
							opacity: 1;
						}
					}
				}
			}
		}
	}
}


body
{
	&.work
	{
		.instagram
		{
			@include breakpoint('large')
			{
				margin-top: 0;
				margin-bottom: rem-calc(30);
			}
		}
	}
}