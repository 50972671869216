body
{
	color: lighten($black, 20%);
	font-family: 'AvenirLTStd-Roman';
	font-size: rem-calc(15);
	line-height: rem-calc(19);
	overflow-y: scroll;

	@include breakpoint(large)
   	{
		font-size: rem-calc(18);
		line-height: rem-calc(30);
   	}
}

html
{
	height: 100%;
}


.kontrast__body	
{
	padding: 0 rem-calc(20);

	@include breakpoint(large)
   	{
   		padding: 0;
   	}
}