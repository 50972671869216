.button__inline
{
	display: inline-block;
}

.button__dark
{
	height: rem-calc(50);
	padding: 0 rem-calc(20);
	border: 0;
	background: $blue;
	color: $pale-green;
	font-size: rem-calc(15);
	font-family: 'Avenir-Heavy';
	text-decoration: none;
	text-transform: uppercase;
	cursor: pointer;
	
	&.line__height
	{
		line-height: rem-calc(53);
	}
	
	&:hover
	{
		color: $white;
	}
}

.button__pale
{
	height: rem-calc(50);
	padding: 0 rem-calc(20);
	border: 0;
	background: $pale-green;
	color: $white;
	font-size: rem-calc(15);
	font-family: 'Avenir-Heavy';
	text-transform: uppercase;
	cursor: pointer;

	&.line__height	
	{
		line-height: rem-calc(53);
	}

	&:hover
	{
		color: $white;
	}
}

// submit buttons
input[type="submit"]:not(.button__dark)
{
	display: inline-block;
	width: 100%;
	height: rem-calc(50);
	margin-top: rem-calc(10);
	padding-top: rem-calc(8);
	border: 0;
	background: $white;
	color: $pale-green;
	font-size: rem-calc(15);
	font-family: 'Avenir-Heavy';
	text-transform: uppercase;
	cursor: pointer;

	@include breakpoint(large)
	{
		width: rem-calc(125);
		margin: 0;
	}
}