body
{
	&.home
	{
		/* this sits behind .top-header ; on this page, it is fixed just like .top-header and fades in when page scrolls */
		.top-header-height {
			position: fixed;
			opacity: 0;
			background-color: #fff;
			z-index: 10;
			transition: opacity 0.2s linear;
			&.opaque {
				opacity: 1;
			}
		}

		height: 100%;
		//background-image: url('/images/home-bg-mob.jpg');
		background-position: 50% 50%;
		background-size: cover;
		background-repeat: no-repeat;

		@include breakpoint(large)
    	{
    		//background-image: url('/images/home-bg.jpg');
    	}

    	.off-canvas-content
    	{
    		height: 100%;
    	}

		//header
		//{
		//	position: absolute;
		//	left: 0;
		//	right: 0;
        //
		//	.logo
		//	{
		//		background-image: url('/img/admin/kontrast.png');
		//	}
		//}

		.hamburger
		{
			.hamburger__box
			{
				.hamburger__inner
				{
					// background: $white;

					&:before
					{
						// background: $white;
					}

					&:after
					{
						// background: $white;
					}
				}
			}
		}

		nav
		{
			ul
			{
				li
				{
					a
					{
						// color: $white;
						color: $black;

						&:hover
						{
							// color: $white;
							color: $black;
						}
					}
				}
			}
		}

		.kontrast__body,
		.kontrast__content
		{
			//height: 100%;
		}

		.kontrast__content
		{
			display: flex;
			display: -webkit-flex;
			flex-direction: column;

			.home__content
			{
				//@include flex-grid-row();
				//-webkit-box-flex: 1;
				//flex-grow: 1;

				margin-left: rem-calc(-20);
				margin-right: rem-calc(-20);
				height: rem-calc(350);
				//background-image: url('/images/home-bg-mob.jpg');
				background-position: 50% 50%;
				background-size: cover;
				background-repeat: no-repeat;
				position: relative;
				overflow: hidden;

				.row
	    		{
	    			height: 100%;
	    		}

	    		.home__backgrounds
	    		{
	    			position: absolute;
	    			left: 0;
	    			right: 0;
	    			top: 0;
	    			bottom: 0;
	    			z-index: 1;

	    			.home__background
	    			{
	    				width: 100%;
	    				height: 100%;
	    				background-repeat: no-repeat;
	    				background-position: 50% 50%;
	    				background-size: cover;
	    			}

	    			.slick-list,
	    			.slick-track
	    			{
	    				height: 100%;
	    			}
	    		}

				@include breakpoint(large)
		    	{
		    		margin: 0;
		    		height: rem-calc(750);
		    	}

		    	.home__content__block
		    	{
		    		height: 100%;
		    		@include flex-grid-row('collapse', 'expand');
					-webkit-box-flex: 1;
					flex-grow: 1;
					position: relative;
					z-index: 2;
		    	}

				h2
				{
					width: 100%;
					margin-bottom: rem-calc(20);
					// color: $white;
					color: $black;
					font-size: rem-calc(17);
					line-height: rem-calc(23);
					font-family: 'Avenir-Heavy';
					letter-spacing: rem-calc(1);
					text-align: center;
    				align-items: flex-end;
    				-webkit-align-items: flex-end;
    				display: flex;
    				display: -webkit-flex;
					
					margin-top: 78px;

    				br
    				{
    					display: none;
    				}

    				@include breakpoint(large)
    				{
    					font-size: rem-calc(40);
						line-height: rem-calc(56);
					    margin-top: 0;

						br
						{
							display: block;
						}
    				}

    				span
    				{
    					display: block;
    					width: 100%;
    					padding: 0 rem-calc(40);
    					text-align: center;

    					@include breakpoint(large)
    					{
							padding: 0;
    					}
    				}
				}

				p
				{
					width: 100%;
					text-align: center;

					a
					{
						// color: $white;
						color: $black;
						font-size: rem-calc(12);
						font-family: 'Avenir-Heavy';
						
						background: $blue;
						color: #fff;
						display: inline-block;
						padding: 10px 20px;

						@include breakpoint(large)
	    				{
							background: transparent;
							color: $black;
							padding: 0 none;
	    					font-size: rem-calc(15);
	    				}
					}
				}
			}
			
			/*
			.home__footer__content
			{
				@include flex-grid-row();
				padding: 0 rem-calc(40);

				@include breakpoint(large)
	    		{
	    			padding: 0;
	    		}

				p
				{
					color: $black;
					font-size: rem-calc(13);
					text-align: center;

					@include breakpoint(large)
	    			{
	    				margin-bottom: rem-calc(30);
	    				color: $white;
	    				font-size: rem-calc(15);
	    			}

					strong
					{
						font-family: 'Avenir-Heavy';
					}
				}
			}
			*/
		}

		footer
		{
			// display: none;
		}
	}
}