.subscribe
{
	@include breakpoint(small only)
	{
		width: 100%;
	}

	@include breakpoint(medium only)
	{
		width: rem-calc(300);
		margin: 0 auto;
	}

	h3
	{
		margin: rem-calc(20) 0 0;
		color: $white;
		font-family: 'AvenirLTStd-Roman';
		font-size: rem-calc(15);
		text-align: center;

		@include breakpoint(large)
		{
			margin: 0 0 rem-calc(30);
			text-align: right;
		}
	}

	form
	{
		font-size: 0;

		input[type="text"]
		{
			display: inline-block;
			width: 100%;
			height: rem-calc(50);
			margin: 0;
			padding: 0 rem-calc(10);
			background: none;
			border: 0;
			border-bottom: 1px solid $white;
			box-shadow: none;
			color: $white;
			font-size: rem-calc(15);
			text-align: center;
			vertical-align: top;

			@include breakpoint(large)
			{
				width: rem-calc(250);
				text-align: right;
			}

			@include placeholder
			{
				color: $white;
			}
		}

		button
		{
			display: inline-block;
			width: 100%;
			height: rem-calc(50);
			margin-top: rem-calc(10);
			border: 0;
			background: $white;
			color: $pale-green;
			font-size: rem-calc(15);
			font-family: 'Avenir-Heavy';
			text-transform: uppercase;
			cursor: pointer;

			@include breakpoint(large)
			{
				width: rem-calc(125);
				margin: 0;
			}
		}
	}
}