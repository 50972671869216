/* Template: 
	<div class="triangle right"></div>
*/

.triangle
{
	&.right
	{
		width: 0px;
		height: 0px;
		border-style: solid;
		border-width: rem-calc(10) 0 rem-calc(10) rem-calc(15);
		border-color: transparent transparent transparent #ccc;
	}
}