@font-face 
{
  	font-family: 'AvenirLTStd-Roman';
  	src: 	url('/fonts/AvenirLTStd-Roman.eot?#iefix') format('embedded-opentype'),  
  			url('/fonts/AvenirLTStd-Roman.otf')  format('opentype'),
	    	url('/fonts/AvenirLTStd-Roman.woff') format('woff'), 
	    	url('/fonts/AvenirLTStd-Roman.ttf')  format('truetype'), 
	    	url('/fonts/AvenirLTStd-Roman.svg#AvenirLTStd-Roman') format('svg');
  	font-weight: normal;
  	font-style: normal;
}


@font-face 
{
  	font-family: 'AvenirLTStd-Book';
  	src: 	url('/fonts/AvenirLTStd-Book.eot?#iefix') format('embedded-opentype'),  
  			url('/fonts/AvenirLTStd-Book.otf')  format('opentype'),
	     	url('/fonts/AvenirLTStd-Book.woff') format('woff'), 
	     	url('/fonts/AvenirLTStd-Book.ttf')  format('truetype'), 
	     	url('/fonts/AvenirLTStd-Book.svg#AvenirLTStd-Book') format('svg');
  	font-weight: normal;
  	font-style: normal;
}


@font-face 
{
  	font-family: 'AvenirLTStd-Light';
  	src: 	url('/fonts/AvenirLTStd-Light.eot?#iefix') format('embedded-opentype'),  
  			url('/fonts/AvenirLTStd-Light.otf')  format('opentype'),
	     	url('/fonts/AvenirLTStd-Light.woff') format('woff'), 
	     	url('/fonts/AvenirLTStd-Light.ttf')  format('truetype'), 
	     	url('/fonts/AvenirLTStd-Light.svg#AvenirLTStd-Light') format('svg');
  	font-weight: normal;
  	font-style: normal;
}


@font-face 
{
    font-family: 'Avenir-Heavy';
    src:    url('/fonts/Avenir-Heavy.eot?#iefix') format('embedded-opentype'),  
            url('/fonts/Avenir-Heavy.otf')  format('opentype'),
            url('/fonts/Avenir-Heavy.woff') format('woff'), 
            url('/fonts/Avenir-Heavy.ttf')  format('truetype'), 
            url('/fonts/Avenir-Heavy.svg#Avenir-Heavy') format('svg');
    font-weight: normal;
    font-style: normal;
}




h2
{
	font-family: 'Avenir-Heavy';
	font-size: rem-calc(18);
	line-height: rem-calc(24);
  letter-spacing: rem-calc(0.5);

    br
    {
        display: block;
    }

    @include breakpoint(large)
    {
        font-size: rem-calc(28);
        line-height: rem-calc(36);

        br
        {
            display: none;
        }
    }
}

p {
	font-size: rem-calc(18);
	// line-height: (rem-calc(25) + (rem-calc(18) / 2));
}


h3 {
	&.section-heading {
		font-family: 'AvenirLTStd-Book';
		font-size: rem-calc(18);
		line-height: rem-calc(24);
		letter-spacing: rem-calc(0.5);
		text-align: center;
		padding: rem-calc(35) 0 rem-calc(30);
		a {
			font-family: 'Avenir-Heavy';
			color: #000;
		}
	}
}