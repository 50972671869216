.enquire 
{
	background: $pale-green;
	.top-header {
		background-color: $pale-green;
	}

	.heading__text
	{
		margin-bottom: rem-calc(50);

		h2
		{
			margin-right: rem-calc(100);
			font-family: 'Avenir-Heavy';
			//font-size: rem-calc(18);
			//letter-spacing: rem-calc(1);
			color: $white;

			@include breakpoint(large)
			{
				//font-size: rem-calc(34);
				//line-height: rem-calc(48);
			}

			br 
			{
				display: block;
			}
		}

		p 	
		{
			//font-size: rem-calc(20);	
		}
	}


	.enquire__form
	{
		.input-group
		{
			margin: 0px;
		}

		.left,
		.right 
		{
			.upper 
			{
				min-height: rem-calc(300);
			}
		}

		.left
		{
			@include breakpoint(large up)
			{
				padding-right: rem-calc(30);
			}
		}

		.right
		{
			@include breakpoint(large up)
			{
				padding-left: rem-calc(30);	
			}
		}

		.input-group
		{
			input-group-field
			{
				width: 100%;
			}

			.input-group-label
			{
				display: none;
			}
		}
	}
}
